var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"card-header"},[_c('b-card-title',[_vm._v("Link (id="+_vm._s(_vm.id)+")")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.onRemove()}}},[_vm._v(" Remove Link ")])],1),_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","responsive":"","items":_vm.data,"thead-class":"hidden"},scopedSlots:_vm._u([{key:"cell(field)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(value)",fn:function(ref){
var item = ref.item;
var value = ref.value;
var key = ref.field.key;
return [(!item.isEditing)?[_vm._v(_vm._s(value))]:_c('b-form-input',{model:{value:(item[key]),callback:function ($$v) {_vm.$set(item, key, $$v)},expression:"item[key]"}})]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(value === true)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v(" "+_vm._s(item.isEditing ? "Save" : "Edit")+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('chartjs-bar-chart-ex',{attrs:{"title":"Activity by day","data":_vm.actData}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('good-table-basic-ex',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"title":_vm.title,"onAction":_vm.onAction,"pageNum":_vm.curPage,"filter":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }